import { defineAsyncComponent } from 'vue'
import { DEFAULT_GAME } from '~/constants'

export function getLeftMenu() {
  const TABS = [
    {
      index: 1,
      key: 'sports',
      title: 'Thể thao',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-ball.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-ball-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/home/sports/index.vue'))
    },
    {
      index: 2,
      key: 'blackjack',
      active: 'livecasino-type',
      title: 'Live Casino',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-casino.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-casino-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/livecasino/index.vue'))
    },
    {
      index: 3,
      key: 'quay-so',
      name: 'quay-so',
      active: 'game-type',
      title: 'Quay số',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-quay-so.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-quay-so-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.slots
      }
    },
    {
      index: 4,
      key: 'game-bai',
      name: 'game-bai',
      active: 'game-type',
      title: 'Game Bài',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-game-bai.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-game-bai-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.ingame
      }
    },
    {
      index: 5,
      key: 'table-game',
      name: 'table-game',
      active: 'game-type',
      title: 'Table Games',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-table-game.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-table-game-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.keno
      }
    },
    {
      index: 6,
      key: 'lo-de',
      name: 'lo-de',
      active: 'game-type',
      title: 'Lô đề',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-lo-de.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-lo-de-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.fishing
      }
    },
    {
      index: 7,
      key: 'no-hu',
      name: 'no-hu',
      active: 'game-type',
      title: 'Nổ hũ',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-no-hu.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-no-hu-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.quickgame
      }
    },
    {
      index: 8,
      key: 'slots',
      name: 'slots',
      active: 'game-type',
      title: 'Slots',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-slots.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-slots-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.cockfight
      }
    },
    {
      index: 8,
      key: 'ban-ca',
      name: 'ban-ca',
      active: 'game-type',
      title: 'Bắn cá',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-ban-ca.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-ban-ca-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.cockfight
      }
    },
    {
      index: 8,
      key: 'game-nhanh',
      name: 'game-nhanh',
      active: 'game-type',
      title: 'Game nhanh',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-game-nhanh.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-game-nhanh-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.cockfight
      }
    },
    {
      index: 8,
      key: 'recently',
      active: 'game-type',
      title: 'Chơi<br/>gần đây',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-choi-gan-day.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-choi-gan-day-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue')),
      props: {
        category: DEFAULT_GAME.cockfight
      }
    }
  ]

  return { TABS }
}
